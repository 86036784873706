import React, { useEffect } from 'react'
import { Provider as MobXProvider } from 'mobx-react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { I18nProvider } from '@elo-kit/components'

import 'libs/payer'
import 'libs/common/analytics'

import { initSentry } from 'libs/common/sentry'

import { AdminMode } from 'shared/components/admin-mode/AdminMode'
import ApiBranch from 'shared/components/api-branch/ApiBranch'

import { ACTIVE_PROFILES } from 'constants/profile.constants'

import { profile } from 'utils/profileHelper.utils'
import { handleInitCabinetFetching } from 'utils/requests.utils'

import { MembershipTheme } from './components/common/MembershipTheme'
import { MetaTags } from './components/common/MetaTags'
import { PageWrapper } from './components/common/page-wrapper'
import { CookieBot } from './components/common/CookieBot'

import { ModalsRenderer } from './components/modals'

import { usePayerStore } from './hooks/use-payer-stores'

import { Routes } from './routes'

profile.setProfileType(ACTIVE_PROFILES.payer)

initSentry()

import './index.scss'

export const NewPayerApp = () => {
  const stores = usePayerStore()
  const { userStore, payerStore, countriesStore } = stores

  useEffect(() => {
    handleInitCabinetFetching(userStore, payerStore, false)
    countriesStore.fetchList()
  }, [])

  return (
    <I18nProvider>
      <MobXProvider {...stores}>
        <Router>
          <MetaTags />
          <PageWrapper>
            <Routes />
            <ModalsRenderer />
            <ApiBranch />
            <AdminMode userEmail={userStore?.item?.email} />
          </PageWrapper>
          <MembershipTheme />
        </Router>
        {/* The user flow integration is temporarily disabled for the purpose of cost-saving,
            as we rarely conduct peer feedback-related campaigns. Uncomment if necessary in the future.
            https://ablefy.atlassian.net/browse/GROW-8
          <UserFlow />*/}
        <CookieBot />
        <ToastContainer />
      </MobXProvider>
    </I18nProvider>
  )
}
